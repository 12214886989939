import React from "react";
import Link from "next/link";
import Image from "next/image";
import Slider from "react-slick";
import Skeleton from "@mui/material/Skeleton";
import { isMobile } from "react-device-detect";
import { Paginate } from "@Models/model";
import { ImageView } from "@Components/image";
import SocialFeed, { ISocialFeed } from "@Models/socialFeed.model";

interface IProps {
   socialFeeds?: Paginate<SocialFeed, ISocialFeed>;
}

const settingsSlider = {
   dots: false,
   speed: 500,
   slidesToShow: 6,
   slidesToScroll: 1,
   loop: false,
   infinite: false,
   responsive: [
      {
         breakpoint: 576,
         settings: {
            slidesToShow: 2.5,
            slidesToScroll: 2,
         },
      },
   ],
};

const SocialFeeds: React.FC<IProps> = ({ socialFeeds }) => {
   // when no data initial
   if (!socialFeeds) {
      return (
         <div className="social-feed-slider">
            <Slider {...settingsSlider}>
               {[...Array(isMobile ? 3 : 6)].map((_element, i) => (
                  <div className="social-feed-item" key={i}>
                     <Skeleton animation="wave" width="100%" height={200} />
                  </div>
               ))}
            </Slider>
         </div>
      );
   }

   // when have data
   return (
      <section className="article">
         <div className="social-feed-slider">
            <Slider {...settingsSlider}>
               {socialFeeds?.docs?.map((doc, i) => {
                  const media = doc.__thumbnailMedia
                     ? doc.__thumbnailMedia
                     : doc.__medias
                     ? doc.__medias[0]
                     : undefined;
                  return (
                     <div className="social-feed-item" key={i}>
                        <div className="position-relative">
                           <Link
                              title={doc.ownedBy?.doc?.publicInfo?.nickname ?? ""}
                              href={`/post/${doc._id}`}
                              className="text-decoration-none"
                           >
                              <div className="img">
                                 <ImageView objectFit="cover" src={media?.$url5And6ColResizeMax ?? ""} />
                              </div>
                              <div className="truncate-line-1 username">{doc.ownedBy?.doc?.publicInfo?.nickname}</div>
                           </Link>
                           <Link href={`/tai-khoan/${doc.ownedBy?.doc?._id}`}>
                              <Image
                                 width={34}
                                 height={34}
                                 src={doc.ownedBy?.doc?.__media?.$url}
                                 alt="Space T"
                                 className="avatar-socialfeed"
                              />
                           </Link>
                        </div>
                        {doc?.info?.description && <div className="description_trend">{doc?.info?.description}</div>}
                     </div>
                  );
               })}
            </Slider>
         </div>
      </section>
   );
};

export default SocialFeeds;
